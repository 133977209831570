.app {
    display: flex;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }

  .menu-button {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    z-index: 1100;
  }
  .sidebar {
    width: 250px;
    background: #f5f5f5;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transform: translateX(0);
    transition: transform 0.3s ease;
    /* overflow-y: auto; */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures space between chat list and logout button */
    height: 100vh; /* Full height sidebar */
  }
  .sidebar.hidden {
    transform: translateX(-300px);
  }
  
  .sidebar.visible {
    transform: translateX(0);
  }
  .chat-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s ease;
    margin-left: 0;
  }
  .chat-container.sidebar-active {
    margin-left: 0;
  }
  .chat-list {
    flex:1;
    margin-top: 20px;
    overflow-y: auto;
  }
  
  .chat-item {
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 5px;
    background: #ffffff;
    transition: background 0.2s ease, color 0.2s ease;
  }
  
  .chat-item:hover {
    background: #e0e0e0;
  }
  
  .chat-item.active {
    /* background: #007bff; */
    background: #ff4d4f;
    color: #ffffff;
  }
  .chat-header {
    padding: 10px;
    text-align: center;
    margin: 0;
    background-color: #fff3e6;
    border-bottom: 1px solid #ccc;
    font-family: 'Cinzel', serif;
    font-size: 24px;
    color: #5d3a00;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 200;
  }
  .chat-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* background: #f9f9f9; */
    overflow: hidden;
    /* margin-top: 40px; */
  }
  
  .messages {
    flex: 1;
    overflow-y: auto;
    padding: 5px;
    display: flex;
    /* background: #fff; */
    flex-direction: column;
    gap: 10px;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1); /* Subtle separator */
  }
  
  /* Message Bubbles */
.message-bubble {
    max-width: 85%;
    padding: 0px 15px;
    border-radius: 15px;
    font-size: 16px;
    line-height: 1.4;
    word-wrap: break-word;
    position: relative;
  }
  
  /* User Messages */
  .user-bubble {
    max-width: 60%;
    /* background-color: #007bff; */
    background: #ff4d4f;
    color: white;
    align-self: flex-end;
    border-bottom-right-radius: 5px;
  }
  
  /* Astrologer's Messages */
  .astrologer-bubble {
    max-width: 90%;
    background-color: #ffebc9;
    color: #5d3a00;
    align-self: flex-start;
    border-bottom-left-radius: 5px;
    position: relative;
  }
  
  /* Astrologer's Icon */
  .astrologer-icon {
    position: absolute;
    top: -10px;
    left: -10px;
    background: #ffd700;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 12px;
    color: #5d3a00;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .input-container {
    display: flex;
    padding: 5px;
    background: #f5f5f5;
    border-top: 1px solid #ccc;
    align-items: center;
    gap: 1px;
    
    /* max-width: fit-content; */
    }
  
  .input-container textarea {
    flex: 1;
    font-family:poppins;
    resize: none;
    padding: 0px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    line-height: 18px; /* Line height of 20px */
    max-height: 36px; /* 4 lines max */
    overflow-y: auto;
    min-height: 18px;
  }
  
  .input-container button {
    padding: 10px 20px;
    /* background: #007bff; */
    background: #ff4d4f;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .welcome-message {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #666;
  }
  .login-page {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: #f9f9f9;
    text-align: center;
  }
  
  .login-page h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .login-page form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .login-page input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login-page button {
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background: #ff4d4f;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-page button:hover {
    background: #cc4d4f;
  }
  
  .login-page p {
    margin-top: 15px;
    color: #333;
  }

  /* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Transparent dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above all other elements */
  }
  
  /* Modal Content */
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    text-align: center;
    position: relative;
  }
  
  .close-button {
    background: transparent;
    border: none;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .modal-body {
    margin-top: 20px;
  }
  
  .stars {
    display: flex;
    gap:5px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .star {
    font-size: 24px;
    color: gray;
    cursor: pointer;
    transition: color 0.3s;
  }
  .star.filled {
    color: gold; /* Filled stars */
  }
  textarea {
    width: 100%;
    height: 80px;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  .modal-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: #007bff;
    color: white;
    transition: background 0.3s;
  }
  
  .modal-buttons button.active {
    background: #0056b3;
  }
  
  .modal-buttons button:hover {
    background: #0056b3;
  }
  @media (max-width: 568px) {
    .menu-button {
      display: block;
    }
  
    .sidebar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      z-index: 1000;
      font-size: 14px;
      padding: 5px;
      /* width: 200px;
      padding: 10px; */
    }
  
    .chat-container.sidebar-active {
      margin-left: 0;
    }
    .input-container {
        /* flex-direction: column; */
        gap: 0px;
        margin: 0px;
        padding: 10px;
        max-width: 100%;
        box-sizing: none;
        max-height: 40px;
        padding-bottom: 15px;
    }
    .chat-header {
        font-size: 16px;
    }
    .input-container textarea {
    font-size: 14px;
/*     padding: 5px; */
    max-width: 100%;
    max-height: 40px;
    }
    .input-container button {
        font-size: 14px;
        padding: 5px;
        max-width: 100%;
    }
    .message-bubble {
        max-width: 90%;
        padding: 0px 15px;
        border-radius: 15px;
        font-size: 14px;
        line-height: 1.4;
        word-wrap: break-word;
        position: relative;
    }
  }
